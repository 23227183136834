import React, { useEffect, useState } from "react";
import { BrowserEdge, EnvelopeFill, GeoAltFill, Linkedin, TelephoneFill } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";

var isPublic = true;

function Resume(){

    const [headerHeight, setHeaderHeight] = useState("0px");
    const [opacity, setOpacity] = useState("0");
    const [isDesktop, setDesktop] = useState(window.innerWidth > 750);

    let asideStyle = {};
    let titleTop = "117px"
    let headerTop = "188px";
    if(!isPublic){
        asideStyle = {"marginTop":"0em"}
        titleTop = "28px"
        headerTop = "100px";
    }

    const updateMedia = () => {
        setDesktop(window.innerWidth > 750);
      };

    const personalStatement = () => {
        return <p id="personal-statement">
            With over five years of experience as a Full Stack Developer, I have consistently delivered robust and secure web services, specializing in Java Spring and React Frameworks.
            From a Digital Developer collaborating with marketing teams, to a Systems Developer managing over forty services, I gained the experience necessary to transition into a leadership role where I oversaw projects, managed workloads, and developed a collaborative team environment.
            My strong architectural mindset, DevOps proficiency, and automation & containerization using Jenkins & Kubernetes have led to several notable achievements, as described in detail below.
        </p>
    } 

    useEffect(()=>{

        setHeaderHeight(headerTop); 
        setTimeout(setOpacity,100,"1");
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, [])

    return (
        <div className="container">

            {isPublic && false &&
                <div className="backButton">
                    <Link to="/">Back</Link>
                </div>
            }

          <header style={{"height":headerHeight}}>
                <img className="bg" src="backdrop.jpg"/>
            </header>
            <main>
              <aside style={asideStyle}>
                {
                    isPublic && 
                    <div className="overlay" style={{"top": "60px"}}>
                        <img className="headshot" src="Sam Gerard.jpg"/>
                    </div>
                }
                
                <div className="contact">
                    <div><GeoAltFill/><span/>Vancouver, British Columbia</div>
                    {!isPublic && <div><TelephoneFill/><span/>+1 (236) 979 9455</div>}
                    {!isPublic && <div><EnvelopeFill/><span /><a href="mailto:sggerard1997@gmail.com">sggerard1997@gmail.com</a></div>}
                    {/*<div><GiftFill/><span/>December, 1997</div>*/}
                    <div><Linkedin/><span/><a href="https://www.linkedin.com/in/sam-gerard/">linkedin.com/in/sam-gerard/</a></div>
                    <div><BrowserEdge/><span/><a href="https://sgerard.dev">sgerard.dev</a></div>
                </div>
                <div style={{"height": "17px"}}></div>
                <div className="personal-statement-aside">
                    {!isDesktop && personalStatement()}
                </div>
                <h5 className="title">EDUCATION</h5>
                <strong>Bachelor of Information and Communication Technologies</strong><p style={{"lineHeight": "1"}}> Ara Institute of Canterbury, 2018</p>
                <h5 className="title">TECHNICAL SKILLS</h5>
                <ul>
                    <li>Java, Spring Boot, Gradle</li>
                    <li>MSSQL, MYSQL</li>
                    <li>Jenkins</li>
                    <li>Docker, Kubernetes</li>
                    <li>Git using Gitflow model, Bitbucket</li>
                    <li>JIRA, ServiceNow</li>
                    <li>Javascript, CSS</li>
                    <li>ReactJS, Redux</li>
                </ul>
               </aside>
            <div className="text">
                <div className="overlay" style={{"top": titleTop, "transition":"opacity 2s", "opacity":opacity}}>
                    <div style={{"display": "flex", "alignItems": "center", "flexDirection": "column", "justifyContent": "center"}}>
                        <h1>SAMUEL GERARD</h1>
                        <h5 style={{"paddingLeft":"0px"}}>FullStack Developer</h5>
                    </div>
                </div>
                <div className="personal-statement-text">
                    {isDesktop && personalStatement()}
                </div>
                    <h5 className="title title-padding">PROFESSIONAL EXPERIENCE</h5>
                    <div className="we-header" >
                        <div className="we-header">
                            <div>
                                <strong className="sub">Team Lead - Systems Developer | Ara Institute of Canterbury</strong>
                            </div>
                        </div>
                        <h6>Nov 2021 - Nov 2023</h6>
                    </div>
                    Responsible for implementing and overseeing the standards and processes required to ensure the quality, security, and maintainability of services. Managed team performance by handling or escalating issues and balancing workloads. Projects included:
                    <ul>
                        <li>Docker Build Replacement: Tested proof of concept utilising AWS Code Artifact and the Kubernetes Init Container design pattern to eliminate the need to build individual container images per service. Collaborated with the DevOps engineer to implement solution.  The project resulted in a reduction to just four custom base images, significantly reducing storage space in Azure and cost. It also facilitated easier image updating and patching of vulnerabilities at the image level, improving security. Additionally there was a decrease in service deployment and rollback time.</li>
                        <li>Central Gradle B.O.M (bill of materials): Researched, developed, and oversaw the implementation to centralize all Gradle library dependencies. This significantly reduced the maintenance workload, enabling patching and updates to occur in one place. Previously, updating all services would take over a month, but now, a full rebuild and testing of all services can be in done in just two days, utilizing a custom script automatically running Jenkins pipelines.</li>
                        <li>LDAP Replacement: Built a .Net/C# SCIM application, hosted in Azure, to handle user provisioning for Equella (A digital repository for Moodle) allowing for the termination of LDAP, reducing costs for the IT department.</li>
                    </ul>
                    <br/>
                    Leadership duties and achievements included:
                    <ul>    
                        <li>Acted on the WebOps committee, providing technical input onto all changes to the public web platform. Collaborated with various internal services including IT, student management systems team, web team, and the curriculum team. </li>
                        <li>Facilitated daily standups, tracked job progress via JIRA and conducted code reviews.</li>
                        <li>Implemented weekly developer research time and sharing sessions.</li>
                        <li>Succeeded in upskilling the team and developing a cohesive environment.</li>
                        <li>Attended the 2023 NZ Tertiary ICT Conference and presented "Streamlining Kubernetes with Jenkins" with Vinay Varma. Provided training sessions to teams from other educational institutes on the topic. </li>
                    </ul>
                    <p></p>

                    {
                        !isPublic && <div><br/><br/><br/></div>
                    }
      
                    <div className="we-header" >
                        <div className="we-header">
                            <div>
                                <strong className="sub">Systems Developer | Ara Institute of Canterbury</strong>
                            </div>
                        </div>
                        <h6>Oct 2019 - Nov 2021</h6>
                    </div>
                    Developed and maintained Java Spring integrations and Restful APIs, as well as React websites for a wide range of services. Specifically:
                        <ul>
                            <li>Program & Course API for the Ara DXP & Study Spy: Actively collaborated with multiple departments and stakeholders to replace the public Ara website, in a complete ground up re-design. Performed a pivotal role defining the data structure and building the API for the vendor to securely consume the data. Oversaw the future development on the Web Operations Committee, as described above.</li>
                            <li>RealMe© Integration for Recruitment: RealMe© is a government verified online profile in New Zealand. Worked with the NZ Government Department of Internal Affairs to generate certificates and meet their approval criteria. This project allowed for verification of new staff without manually verifying their identity onsite, improving the efficiency of the recruitment process. </li>
                            <li>Financial Services Integration: Wrote the integration of procurement data, from Unimarket into Dynamics 365, shifting the finance department from a paper based, manual entry system, to a digital system. This significantly reduced process time and therefore increased productivity of the finance department.</li>
                            <li>MyAra API: Improved the timetabling, attendance, and results API for the student MyAra platform. Identified issues with the timetabling query and optimized the overall code for efficiency. Added support for date filtering.</li>
                            <li>Photo Service: Developed a new service to integrate student and staff photos across multiple platforms, including Office 365 and Tribal SMS. Wrote an API for other services to easily access and store photos from a centralized location, improving reusability.</li>
                            <li>Pronoun Service: Developed a website to allow students and staff to select their pronouns which then updated their active directory profile. This promoted diversity and inclusion across campus.</li>
                        </ul>
                        <p></p>

                    <div className="we-header" >
                        <div className="we-header2">
                            <div>
                                <strong className="sub">Digital Developer | Ara Institute of Canterbury</strong>
                            </div>
                        </div>
                        <h6>Apr 2019 - Oct 2019</h6>
                    </div>
                    Responsible for content and feature updates to the Ara public website, Staff Intranet, and the Student MyAra platform. Duties performed:
                        <ul>
                            <li>Website Statistics: Created reports on website statistics using Google Analytics, Google Tag Manager, and Google Data Studio. Provided data to the Marketing Department for targeted advertising to increase student uptake and engagement. </li>
                            <li>Performed BAU such as handling user issues, bug fixes, content updates as well as designing and implementing additional tiles for MyAra.</li>
                            <li>Designed and implemented functionality for MyAra including content pages, events tile and global search greatly improving user experience and engagement. Created resources sharing my innovations for the 2019 Myday customer user event to inspire other educational institutions around the world.</li>
                            <li>MyAra V3 Upgrade: Project managed the move to the MyDay V3 platform. Coordinated with the vendor and IT to update URLs and the mobile app as well as the marketing department for communications ensuring seamless user experience.</li>
                        </ul>
                    
                </div>
            </main>
        </div>
      );

}

export default Resume