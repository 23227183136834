import './App.css';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom'

function App() {
  return (
    <div className="container">
          <header style={{"height":"100px"}}>
                <img className="bg" src="backdrop.jpg" />
            </header>
            <main>
              <aside style={{"margin":"0px"}}>
    

                  <div className="badge-base LI-profile-badge" data-locale="en_US" data-size="medium" data-theme="light" data-type="VERTICAL" data-vanity="sam-gerard" data-version="v1"></div>
                
         
              </aside>
              <div className="text">
                <div className="overlay" style={{"top": "28px"}}>
                    <div style={{"display": "flex", "flexDirection": "column", "justifyContent": "center"}}>
                        <h1>SAMUEL GERARD</h1>
                        <h5 style={{"paddingLeft":"0px"}}>FullStack Developer from New Zealand</h5>
                    </div>
                </div>

                <Nav>
                  <Link to="/Resume">Resume</Link>
                  <Link to="/Resume">Dev Blog</Link>
                  <Link to="/Resume">Projects</Link>
                </Nav>

                <h3 className="open-special">Open to work! Please contact me on <a target="_blank" rel="noreferrer" href="https://ca.linkedin.com/in/sam-gerard">Linkedin</a></h3>

                <p>Welcome! My name is Samuel Gerard, originally from New Zealand I now reside in Vancouver Canada with my partner. Please check out the links below!</p>
                    
              </div>
            </main>
          </div>
  );
}

export default App;
